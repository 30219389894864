.App {
}
.App-header .App-icon {
  height: 10vmin;
  width: 10vmin;
}

.App-header a {
  margin-bottom: 10px;
  color: inherit;
  padding: 5px;
}
.App-header a:hover {
  font-weight: bolder;
}
.App-header a.active {
  color: black;
  background: #d0d0d0;
  border-radius: 5px;
}

.iconButton {
  cursor: pointer;
}
.MuiSvgIcon-root.iconPrimary {
  fill: #152738;
}
.MuiSvgIcon-root.iconGreen {
  fill: #6BCE34;
}
.MuiSvgIcon-root.iconGold {
  fill: goldenrod;
}
.MuiSvgIcon-root.iconBlack {
  fill: black;
}
a,
.link {
  text-decoration: none;
  color: inherit;
}
.App .bold {
  font-weight: 600;
}
.App .inputSize-sm {
  min-width: 4rem;
}
.App .inputSize-md {
  min-width: 6rem;
}
.App .inputSize-lg {
  min-width: 7rem;
}
.App .inputSize-xxl {
  min-width: 15rem;
}
.App .bgGreen {
  background-color: #6BCE34;
}
.App .bgOrange {
  background-color: #ff7734;
}
.App .bgRed {
  background-color: #FF2323;
}
.App .bgYellow {
  background-color: #f2eb16;
}
.App .ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.opaqueOnHover {
  opacity: 0.3;
}
.opaqueOnHover:hover {
  opacity: 1;
}
.badge {
  border-radius: 5px;
  border: 1px solid black;
}
